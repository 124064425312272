/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind utilities;

html, body {
	height: 100vh;
	overflow: hidden;
}

body {
	margin: 0;
	font-family: Roboto, "Helvetica Neue", sans-serif;
}

::-ms-reveal {
	display: none !important;
}

a {
	cursor: pointer;
}

mat-sidenav-container {
	height: calc(100vh - 64px);
}


.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
	border-right: none;
//	width: min-content !important;
}

//.mdc-text-field--filled:not(.mdc-text-field--disabled) {
//	background-color: unset;
//}
//
//.mat-mdc-form-field-focus-overlay {
//	background-color: unset;
//}

@import "styles/snackbar";

ckeditor {
	.ck-editor__editable_inline {
		min-height: 300px !important;
	}
}

.pdm-editor {

	a {
		text-decoration: underline;
		cursor: pointer;
		color: blue;
	}

	ul, ol {
		margin: revert;
		padding: revert;
		list-style: revert;
	}

	h1 {
		font-size: 3rem; /* 40px */
	}
	h2 {
		font-size: 2.5rem; /* 32px */
	}
	h3 {
		font-size: 2rem; /* 28px */
	}
	h4 {
		font-size: 1.5rem; /* 24px */
	}
	h5 {
		font-size: 1.25rem; /* 20px */
	}
	h6 {
		font-size: 1rem; /* 16px */
	}

	figure.image {
		margin: .9rem auto;
		clear: both;
		display: table;
		min-width: 50px;
		text-align: center;
	}

	figure.image-style-side {
		float: right;
		margin-left: 1.5rem;
		max-width: 50%;
	}

	p > img {
		float: left;
		max-width: 50%;
		margin-right: 1.5rem;
	}

	hr {
		background: #dedede;
		border: 0;
		height: 4px;
		margin: 15px 0;
	}
}

.list-chip {
	@apply flex items-center justify-center rounded-full text-center p-2 min-w-[100px] w-fit min-h-[40px] font-medium
}