.mat-mdc-snack-bar-container {
	--mdc-snackbar-supporting-text-size: 1rem;
	justify-content: center;
	&.success {
		--mdc-snackbar-container-color: green;
		--mat-mdc-snack-bar-button-color: #fff;
		--mdc-snackbar-supporting-text-color: #fff;
	}

	&.error {
		--mdc-snackbar-container-color: red;
		--mat-mdc-snack-bar-button-color: #fff;
		--mdc-snackbar-supporting-text-color: #fff;
	}
}
